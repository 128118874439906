@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'ProximaNova-Extrabld';
    src: url('../assets/fonts/Proxima-Nova/proximanova-extrabold.otf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 700;
}

body {
    font-family: $roboto-regular;
    margin: 0;
    background-color: #f3f4f6;
}

.container {
    max-width: $container-width;
    margin: 0 auto;
    position: relative;
    &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: transparent;
        z-index: 100;
        margin-bottom: 10px;
        h1 {
            margin-right: 15px !important;
        }
        .button-controls {
            display: flex;
            margin-left: auto;
            & > button {
                font-size: 16px;
                display: flex;
                margin-left: 15px;
                img {
                    padding-right: 11px;
                }
            }
        }
    }
}

.label {
    font-size: $font-size-14;
    color: $light-gray;
    margin-top: 9px;
    margin-bottom: 7px;
}

.form-control {
    box-shadow: $input-box-shadow;
    border: solid 1px #d2dadd;
    height: 38px;
    padding-left: $font-size-14;
    box-sizing: border-box;
    font-size: $font-size-14;
    font-family: 'Roboto-Regular', sans-serif;
}

.form-control:focus {
    outline: none;
}

.btn {
    padding: 11px 20px;
    border-radius: 2px;
    border: 0;
    color: $white;
    font-size: $font-size-14;
    cursor: pointer;
}

.btn-outline {
    padding: 11px 20px;
    border-radius: 2px;
    border: solid 1px transparent;
    font-size: $font-size-14;
    cursor: pointer;
    background: transparent;
}

.btn.green {
    background: $green;

    &:disabled {
        display: inline-flex;
    }
}

.btn.small {
    padding: 8px 20px;
}

.btn-outline.small {
    padding: 8px 20px;
}

.btn-outline.green {
    padding: 5px 20px;
    color: $green;
    border: solid 1px $green;
}

.btn-outline.blue {
    color: $blue;
    border: solid 1px $blue;
}

.btn.blue {
    background: $blue;
}

.btn:disabled {
    pointer-events: none;
    background-color: #dfdfdf;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #9f9f9f;
    cursor: default;
}

.title {
    font: 700 28px 'Roboto-Medium', sans-serif !important;
    color: $black;
    font-size: $font-size-28 !important;
    margin: 23px 0 !important;
}

.btn-add {
    font-size: $font-size-16;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
}

.btn-add {
    img {
        padding-right: 11px;
    }
}

.action-container {
    display: flex;
    flex-direction: row;
    padding: 13px 15px;
    align-items: center;
}

.mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
        height: 20px;
    }

    .delete-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
        opacity: 0.8;
        transition: ease-out;
        padding: 5px;
        border-radius: 50%;
    }

    .delete-icon:hover {
        opacity: 1;
        background: #e0e0e0;
    }
    &[aria-disabled='true'] .mat-expansion-panel-header-title,
    &[aria-disabled='true'] .mat-expansion-panel-header-description {
        color: rgba(0, 0, 0, 0.87);
    }
}

.page-container {
    min-height: 80vh;
    background: $white;
    z-index: 2;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border-bottom: solid 1px #eaeaea;
}

.mat-expansion-panel-spacing {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.14) !important;
}

.mat-expansion-indicator::after {
    content: none !important;
}

.form-panel {
    input,
    select {
        margin-right: 16px;
    }
}

.panel-title {
    padding-bottom: 10px;

    div {
        font-weight: bold;
    }
}

.panel {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;

    div:not(.mat-button-focus-overlay) {
        overflow: hidden;
        margin-right: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.panel-title {
    padding-left: $panel-title-left;
    cursor: pointer;
    border-bottom: solid 2px $footer-divider;
    width: calc(100% - #{$panel-title-left});
}

.panel-buttons-container {
    margin-top: 17px;
    float: right;
    display: flex;
    gap: 10px;

    .btn {
        //margin-right: 7px;
        text-transform: uppercase;
    }
}

/*.panel-serviceTypes {
    .code {
        width: 178px;
    }

    .name,
    .name-english {
        width: 460px;
    }
}*/

.expanded-panel {
    padding: 0 30px;
}

.required-star {
    color: $red;
}

.extension-panel-checkbox {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background-color: $white;
    position: absolute;
    left: 5px;
}

mat-panel-title {
    .panel-serviceTypes {
        padding-left: $panel-title-left - 24px;
    }
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

input.disabled {
    background-color: #eeeeee;
}

.error-text {
    color: $form-error;
    font-size: $font-size-12;

    &.date-error {
        margin-top: 0.5416666667em;
        bottom: -2.6666666667em;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 27px;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-10%);
        animation-name: errAnim;
        animation-timing-function: ease-in-out;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}
span.error-message {
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 20px;
    color: red;
}

@keyframes errAnim {
    0% {
        opacity: 0;
        transform: translateY(-15%);
    }
    80% {
        opacity: 1;
        transform: translateY(-3%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.error-text.bold {
    margin-top: 0;
    font-weight: bold;
}

.invalid-input {
    border-color: $form-error;
}

$spaces: 21px;
.donors {
    .row-div {
        display: flex;
        flex-direction: row;

        div {
            margin-right: $spaces;
        }

        .name-div,
        .name-eng-div {
            width: 50%;

            input {
                width: 95%;
            }
        }

        .boss,
        .edrpo {
            width: 250px;
        }
    }

    .settlement {
        div {
            input {
                width: 250px;
            }
        }
    }

    .address {
        input {
            width: 70px;
        }
    }
}

.cdk-overlay-pane {
    max-height: 92vh;
    max-width: 96vw !important;

    .mat-dialog-container {
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 16px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $gray;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $blue;
    }

    .mat-menu-panel {
        max-width: 465px;
    }
}

.action-arrows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    background: #e2e2e2;
    border-radius: 50%;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;

    fa {
        color: white;
        font-size: 20px;
    }
}

.action-arrows-container:hover {
    background: $blue;
}

.action-arrows-container.left {
    padding-right: 2px;
    margin-right: 20px;
}

.action-arrows-container.right {
    padding-left: 2px;
}

.action-arrows-container.right {
    img {
        left: -15px;
    }
}

.zero-margin {
    margin: 0;
}

.preloader__centered {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

table {
    tr {
        td.hidden-cell {
            visibility: hidden;
        }
    }
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
    display: none;
}

select.form-control {
    padding-left: 14px;
}

.row {
    display: flex;
    flex-direction: row;
}

.full-width {
    width: 100%;

    /*.mat-form-field {
        width: 96.8% !important;
    }*/

    &.clear-select-container {
        .mat-form-field {
            width: 100% !important;
        }
    }
}

@media only screen and (max-height: 900px) {
    #packeges-in-project {
        height: 92vh;
    }
}

.users {
    .users-panel {
        width: 100%;
        box-sizing: border-box;

        div {
            width: 148px;
            overflow: hidden;
        }
    }

    mat-expansion-panel-header {
        .users-panel {
            padding-left: $panel-title-left - 24px;
        }
    }

    .row-div {
        display: flex;
        flex-direction: row;

        div {
            margin-right: 21px;
        }

        input,
        select {
            width: 360px;
        }
    }
}

.green-snackbar {
    background: $green;

    .mat-button-wrapper,
    .mat-simple-snackbar {
        color: white;
    }
}

.organization {
    .row {
        display: flex;
        gap: 20px;
        max-width: 1170px;
        width: 100%;
    }

    form {
        .panel {
            display: unset;

            input,
            select {
                width: 100%;
            }

            .one-third-width,
            .one-third-width-container,
            .half-width,
            .half-width-container,
            .full-width,
            .full-width-container {
                padding: 0 !important;
                margin: 0 !important;
            }

            .one-third-width {
                div:not(.mat-select-value, .mat-select-arrow-wrapper) {
                    width: 33.3333%;
                }
            }

            .half-width {
                div:not(.mat-select-value, .mat-select-arrow-wrapper) {
                    width: 50%;
                }
            }

            /*.district {
                margin-right: 0;
            }

            .flat {
                margin-right: 0;
            }

            .name {
                padding-right: 15px;
            }

            .name-eng {
                padding-right: 15px;
                box-sizing: border-box;
            }*/

            .one-third-width-container {
                width: 33.3333%;
                max-width: 380px; // 1/3 of 1140
            }

            .one-third-width__row {
                margin-right: 0;
            }

            .half-width-container {
                width: 50%;
                max-width: 570px; // 1/2 of 1140
            }

            .half-width__row {
                margin-right: 0;
            }

            .row {
                /*.boss {
                    width: 100%;
                }*/

                .role {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                /*.name {
                    width: 100%;
                }

                .name-eng,
                .code {
                    width: 50%;
                }

                .street {
                    width: 100%;
                }

                .house-detail {
                    //width: 70px;
                    width: 33.3333%;
                }

                .house-detail-container {
                    justify-content: space-between;
                    margin-right: 0;
                }*/
            }
        }
    }

    .organization-panel-title,
    .organization-panel {
        div {
            width: 147px;
        }
    }
}

.multiple-select {
    height: 43px;

    .mat-form-field-wrapper {
        margin-right: 0 !important;

        .mat-form-field-flex {
            margin-right: 0 !important;

            .mat-form-field-infix {
                margin-right: 0 !important;
            }
        }
    }

    .mat-select-trigger {
        margin-right: 0 !important;
    }
}

.chart-container {
    display: block;
    max-height: 400px;
    margin: 10px 0;
}

.list-pagination {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 25px 25px;
    align-items: center;
    box-sizing: border-box;

    .list-pagination__select {
        mat-form-field {
            width: 50px;
        }
    }
}

.my-pagination .ngx-pagination .current {
    background: $blue;
}

.fa-rotate-180-deg {
    color: #9ea5a8;
    transform: rotate(0deg);
    transition-duration: 0.8s;
    transition-property: transform;
}

.fa-rotate-360-deg {
    color: #9ea5a8;
    transform: rotate(180deg);
    transition-duration: 0.8s;
    transition-property: transform;
}

.sort-btn {
    margin-bottom: 15px;
}

.users-panel {
    .organization,
    .role {
        cursor: default;
    }
}

.form-control.organization-control {
    -webkit-appearance: menulist;
}

.mat-drawer {
    background-color: #f3f4f6;
    width: 400px;
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

.form-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 400px;
}

.filter-btn.active {
    background-color: $blue;

    span,
    .mat-icon {
        color: $white;
    }

    .mat-icon {
        margin-left: 7px;
    }
}

.field-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    mat-form-field {
        width: 100%;
    }
}

.sidenav-meetings__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 350px;
    background-color: #f3f4f6;
    height: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 5px;
    z-index: 2000;

    .sidenav-meetings__header-text {
        font-size: $font-size-16;
        font-weight: bold;
        padding-left: 20px;
    }

    .sidenav-meetings__header-icon {
        cursor: pointer;
    }
}

.dates {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    select {
        border: 0;
    }

    .field-form-container {
        width: 125px;
    }
}

.dates.center {
    width: 96%;

    mat-form-field {
        width: 48%;
    }

    select {
        order: 2;
    }

    .date.order {
        order: 3;
    }
}

.sticky {
    position: fixed;
    top: 0;
}

.red-edit {
    -webkit-text-fill-color: #d43f0b;
    justify-self: flex-end;
    margin-left: auto;
    cursor: pointer;
    padding: 6px;
    float: right;
}

.blue-recover {
    color: #2c93f3;
    justify-self: flex-end;
    margin-left: auto;
    cursor: pointer;
    padding: 6px;
    float: right;
}

.mat-content {
    height: 100%;
    align-items: center;
}

.mat-expansion-panel-header-title {
    height: 100% !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.mat-expansion-panel-header {
    padding-right: 7px !important;
}

.filtrations-panel {
    align-items: baseline;

    div {
        display: flex;
        flex-direction: column;
    }
}

.icon-search {
    margin-left: auto;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url('../assets/images/search.svg') center center no-repeat;
    background-size: 37px;
}

.icon-search.active {
    background: url('../assets/images/search-blue.svg') center center no-repeat;
    background-size: 37px;
}

.clear-icon {
    width: 24px !important;
    height: 24px;
    margin-right: 0 !important;
    cursor: pointer;
    background: url('../assets/images/clear.svg') center center no-repeat;
    flex-basis: 24px !important;
}

.clear-icon:hover {
    background: url('../assets/images/clear-hover.svg') center center no-repeat;
}

.input-clear {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;

    input,
    select {
        width: 100%;
        margin-right: 5px;
        height: 30px;
    }
}

.panel-item-with-arrow {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    cursor: pointer;

    fa {
        margin-left: 5px;
    }
}

.mat-select-trigger {
    //width: auto !important;
}

.input-clear-container {
    .input-clear {
        height: 30px;
        border: 1px solid rgb(210, 218, 221);

        .mat-select-value {
            padding-top: 10px;
        }
    }
}

.search-panel {
    padding-left: 59px;
    flex-grow: 0;
    box-sizing: border-box;
    border-bottom: 2px solid rgb(234, 234, 234);
    padding-bottom: 10px;

    div {
        flex-basis: 148px;
        max-height: 19px;

        p {
            font-weight: bold;
            margin: 0;
        }
    }
}

.search-panel.expanded {
    div {
        max-height: 100%;
    }
}

.fa-rotate-180-deg.active,
.fa-rotate-360-deg.active {
    color: black;
}

.fa-rotate-180-deg:not(.active),
.fa-rotate-360-deg:not(.active) {
    transform: rotate(180deg) !important;
}

.date-container {
    display: flex;
}

.clientReasonsForTesting {
    width: 100% !important;
    max-width: unset !important;
}

.clientReasonsForTesting-mat-select {
    width: 361px;

    .mat-form-field-infix {
        width: 337px;
    }
}

.mat-sort-header-arrow,
[dir='rtl'] .mat-sort-header-position-before .mat-sort-header-arrow {
    margin: 0 !important;
}

.table-with-sort {
    width: 100%;
    border-spacing: 0;

    .mat-column-delete .mat-sort-header-content {
        margin-left: auto;
    }

    thead tr.first-row {
        td {
            font-weight: bold;
        }

        height: 40px;
    }

    .icon-search {
        width: 40px;
        height: 40px;
    }

    .left {
        text-align: left;
    }

    thead.left {
        th {
            text-align: left;
        }
    }

    th {
        padding: 0;

        p {
            margin: 0;
            width: min-content;
        }
    }

    td,
    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .content,
    tbody {
        cursor: pointer;
    }

    .content.center {
        text-align: center;
    }

    tbody {
        tr:hover {
            background: rgba(0, 0, 0, 0.04);
        }
    }

    tbody {
        tr {
            height: 48px;
        }
    }

    thead {
        tr {
            height: 64px;
            &.filter-row {
                height: 48px;
                &.new {
                    display: none;
                    &.visible {
                        display: table-row;
                    }
                }
            }
        }

        tr.first-row.active {
            th {
                border-bottom: 0;
            }
        }
    }

    .mat-form-field-label {
        padding-top: 6px;
    }

    .mat-form-field.mat-focused .mat-form-field-label {
        display: none !important;
    }

    .mat-header-cell {
        font: 600 16px/20px Roboto-Regular, sans-serif;
        letter-spacing: normal;
        color: #000000;
    }
    .mat-cell,
    .mat-footer-cell {
        font: 400 16px/20px Roboto-Regular, sans-serif;
        letter-spacing: normal;
        color: #000000;
    }
    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
        padding-right: 0;
    }
}

.client-table {
    padding-left: 58px;
    border-spacing: 0;

    th,
    td {
        width: 176px;
        text-align: left;
    }

    p {
        width: max-content !important;
    }

    .icon-in-title {
        padding: 6px 0;
        width: 100%;
        max-width: unset;
    }
}

.user-table {
    padding-left: 59px;
    padding-right: 0;
    width: 95%;

    th {
        width: 10%;

        p {
            width: 100%;
        }
    }

    .left-align {
        text-align: left;
    }
}

.material-icons__header {
    color: #adadad;
    cursor: pointer;
}

.field-form-container.date {
    width: 115px;
}

.form-group.date {
    position: relative;
    width: 130px;

    mat-datepicker-toggle {
        position: absolute;
        right: 0;
        top: 18px;
    }
}

.date-imitation {
    position: absolute;
    bottom: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(94, 188, 124, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $green;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $green;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $green;
}

.container-bigger {
    max-width: 1570px;
    min-width: 1300px;
}

.arrow-at-the-end {
    position: relative;
    display: inline-block;
    width: 100%;

    input {
        width: 100%;
    }
}

.arrow-at-the-end::before {
    position: absolute;
    content: '';
    top: 5px;
    right: 9px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
}

.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0,
        rgba(0, 0, 0, 0.42) 33%,
        #c2c7cc 0
    ) !important;
    background-size: 1px 100% !important;
    background-repeat: repeat-x !important;
}

.meeting-modal {
    mat-dialog-container {
        padding: 12px 24px;
    }
}

.no-break-lines {
    max-width: 100%;

    td,
    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    table-layout: fixed;
    width: 100%;
}

.checkbox {
    margin-bottom: 25px;
}

.popup-header {
    border-bottom: 2px solid #dedede;
    position: relative;

    h1 {
        margin-top: 0;
        font-weight: normal;
        font-size: 26px;
    }

    .clear-icon {
        position: absolute;
        transform: translateY(-50%);
        right: 0;
        top: 10%;
        bottom: 50%;
    }
}

.full-width-form {
    mat-form-field {
        width: 98%;
    }
}

.same-height {
    .mat-form-field-flex {
        height: 56.5px;
    }
}

.popup {
    .mat-dialog-container {
        padding: 0 0 15px !important;
    }
}

.popup-body {
    padding: 17px 21px;
}

.popup-header {
    h1 {
        margin-bottom: 0;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 21px;

    .clear-icon {
        position: static;
        transform: unset;
    }
}

mat-drawer-container {
    height: 100%;
    z-index: 1;
}

.margin-bottom {
    margin-bottom: 20px;
}

.important-black {
    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: black;
    }
}

.mat-standard-chip {
    height: auto !important;
}

.mat-button-wrapper {
    display: flex;
    align-items: center;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
    margin: 0 !important;
}

.mat-card > :last-child:not(.mat-card-footer),
.mat-card-content > :last-child:not(.mat-card-footer) {
    margin-top: 16px !important;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
    margin: 20px 0 !important;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
    margin: 0 !important;
    font: 700 16px/28px Roboto-Regular, sans-serif !important;
}

.mat-body,
.mat-body-1,
.mat-typography {
    font: 400 16px/20px Roboto-Regular, sans-serif;
    letter-spacing: normal;
}

input.mat-chip-input {
    width: 100% !important;
}

.shadow-card {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25) !important;
}

.shadow-card:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.dashboard {
    .cards-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 25px;
    }

    .cards-orgContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, 400px);
        column-gap: 20px;
        margin-bottom: 25px;
    }

    .dashboard-card {
        width: 28%;
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 41px;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: box-shadow 0.25s;
        cursor: pointer;
        padding: 0;

        &__title {
            overflow: hidden;
            font-size: $font-size-18;
            margin-bottom: 0;
        }

        &__value {
            font-size: 22px;
        }
    }

    .dashboard-card-big,
    .dashboard-card-big-second {
        height: 100px;
        min-width: 350px;
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .dashboard-card-big.none-justify:nth-child(3) {
        margin-right: auto;
        margin-left: 15px;
    }

    .charts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 500px;

        .charts__child {
            width: 48%;
            background: $white;
            height: 100%;
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            h3 {
                margin-top: 0;
                font-size: 1.17em;
                font-weight: 700;
            }
        }

        .charts__child.second {
            dc-dynamics-dates {
                margin-top: 20px;
            }
        }
    }

    .radio-btn-group {
        display: flex;
        max-width: 606px;
        justify-content: space-between;

        .mat-radio-label-content {
            padding-right: 10px;
        }
    }

    .dashboard-card-custom {
        min-width: 150px;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .dashboard-card__value {
            text-align: center;
            font-weight: bold;
            font-size: 55px;
            margin-top: 0;
            margin-bottom: 0;
            font-family: 'ProximaNova-Extrabld', sans-serif;
            line-height: 55px;
        }

        .dashboard-card__title {
            text-align: center;
            color: #6b6b6b;
        }

        border-bottom-width: 5px;
        border-bottom-style: solid;
    }

    .search-input {
        width: 100%;
    }
}

.id-text-in-card {
    padding-bottom: 10px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

mat-dialog-content.mat-dialog-content {
    max-height: none;
}

mat-form-field {
    cursor: pointer !important;
}

color-picker .alpha {
    display: none !important;
}

.violation-popup {
    mat-form-field {
        width: 100%;
    }

    .image-section {
        img {
            display: block;
            margin: 0 auto;
            max-height: 200px;
            width: auto;
        }

        .fixed-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            button:first-child {
                margin-right: 15px;
            }
        }
    }

    #file {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .error-text {
        text-align: center;
    }
}

.DateInput {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &__lookup {
        max-width: 100px;
        margin-right: 15px;
    }

    &__date {
        position: relative;

        .field-form-container.date {
            width: 100%;
        }
    }

    &__range {
        display: flex;
        align-items: center;

        ::ng-deep .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
            width: 180px !important;
        }
    }
}

.dateWrap {
    display: flex;
    justify-content: space-between;
    gap: 7px;
}

.one-third-width-child {
    width: 31%; // 33.3333% but a bit less
}

.half-width-child {
    width: 48%;
}

.action-bottom-buttons {
    display: flex;
    margin-top: 25px;

    button {
        margin-right: 10px;
    }

    &.with-control {
        width: 100%;
        align-items: center;
        .with-control-container {
            display: flex;
            width: 50%;
            margin-right: auto;
            align-items: center;
            .desc-control {
                white-space: nowrap;
                margin-right: 25px;
                margin-bottom: 9px;
            }
        }
        button {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}

.table-with-sort th.filter-column,
.table-with-sort td.filter-column {
    .input-clear {
        overflow: hidden;
    }
    .mat-form-field {
        width: calc(100% - 30px);
        .mat-form-field-infix {
            border-top: 0;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-form-field-underline {
            bottom: 0;
        }
        &.mat-form-field-type-mat-select {
            .mat-form-field-infix {
                padding: 12px 0;
            }
        }
    }
}

.form-row {
    display: flex;
    justify-content: space-between;
    .btn-col {
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }
}
.form-col-1 {
    width: 100% !important;
}
.form-col-1-2 {
    width: 48% !important;
}
.form-col-2 {
    width: 66% !important;
}
.form-col-3 {
    width: 32% !important;
    &.mat-checkbox {
        display: flex;
        align-items: center;
    }
}

.break-label {
    .mat-checkbox-label {
        white-space: break-spaces;
        line-height: 19px;
    }
}

.input-style-label {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin-bottom: auto;
    height: 38px;
    .mat-checkbox-label {
        margin-right: auto;
    }
    .mat-checkbox-layout {
        width: 100%;
    }
    &.mat-checkbox-label-before .mat-checkbox-inner-container {
        margin-right: 0;
    }
}

.input-number {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
}

.cdk-global-scrollblock {
    .cdk-overlay-container {
        z-index: 1050;
    }
}

.mat-standard-chip .mat-chip-remove.mat-icon,
.mat-standard-chip .mat-chip-remove .mat-icon {
    flex: 0 0 18px;
}

.mat-pseudo-checkbox-checked::after {
    top: 2px;
    left: 1px;
    width: 9px;
    height: 4px;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
    opacity: 1;
    box-sizing: content-box;
}

.clear-select-container {
    display: flex;
    align-items: center;
    & > * {
        width: 100%;
    }
}

.indicators-contracts {
    overflow: auto;
    min-width: 100%;
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: 33px;
    mat-accordion {
        overflow: visible;
        display: flex;
        position: relative;
        min-width: 100%;
        flex-direction: column;
        width: auto;
        flex: 0 0 auto;
    }
    .mat-expansion-panel {
        overflow: visible;
        min-width: 100%;
        width: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        flex: 0 0 auto;
        .mat-expansion-panel-header {
            display: flex;
            overflow: visible;
            width: auto;
            //flex: 0 0 100%;
            .mat-content {
                overflow: visible;
                flex: 0 0 auto;
                min-width: 100%;
                width: auto;
            }
            .indicator-title {
                width: auto;
                min-width: 100%;
                flex: 0 0 auto;
            }
            .mat-expansion-panel-header-title {
                width: auto;
                min-width: 100%;
            }
            .mat-col.name:not(.with-periods) {
                //max-width: 1110px;
                //width: 100vw;
            }
            .mat-col.name.with-periods {
                //max-width: 1000px;
            }
            .mat-col.name {
                max-width: 1000px;
            }
        }
        .mat-column-indicatorName.with-periods {
            //width: 400px;
            //max-width: 400px;
        }
        .mat-expansion-panel-content {
            display: none;
        }
        &.mat-expanded {
            .mat-expansion-panel-content {
                display: flex;
            }
        }
    }
}

dc-audit-info {
    .mat-expansion-indicator::after {
        content: '' !important;
    }
    .mat-content {
        max-width: calc(100% - 20px);
    }
}

.scroll-confirm-dialog {
    dc-confirm-dynamic-dialog {
        //max-height: 60vh;
        display: block;
    }
    .confirm-dialog {
        //height: calc(100% - 45px);
        //max-height: calc(60vh - 45px);
    }
    .mat-dialog-content {
        //max-height: 48vh;
    }
}
